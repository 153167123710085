@import "../../global.scss";

.contact{
    background-color: white;
    display: flex;

    @include mobile{
        flex-direction: column;
    }

    .left{
        flex: 1;
        overflow: hidden;

        img{
             height: 100%;
        }
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2{
            font-size: 60px;
        }
        .large{
            width: 150px;
            border: 2px solid crimson;
            padding: 10px;
            border-radius: 10px;
            text-align: center;
            text-decoration: none;
            color: crimson;
            font-weight: bold;
            margin-top: 20px;
            transition: all 0.5s ease;

            &:hover{
                background-color: crimson;
                color: white;
            }
        }
//adjust the class iconbtn to display a small 20px image with a red border
        .iconbtn{
            width: 20px;
            border: 2px solid crimson;
            padding: 10px;
            border-radius: 10px;
            text-align: center;
            text-decoration: none;
            color: crimson;
            font-weight: bold;
            margin-top: 20px;
            transition: all 0.5s ease;

            &:hover{
                background-color: crimson;
                color: white;
            }
        }
        .itemContainer{
            display: flex;
            align-items: center;
            margin-top: 30px;

            .icon{
                font-size: 18px;
                margin-right: 5px;
            }
            span{
                font-size: 15px;
                font-weight: 500;
            }

        }
        .specContainer{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .icon{
                font-size: 18px;
                margin-right: 5px;
            }
            span{
                font-size: 15px;
                font-weight: 500;
            }

        }
        form{
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            

            input{
                width: 300px;
                height: 30px;
                font-size: 14px;

                @include mobile{
                    width: 200px;
                    height: 20px;
                }
            }
            textarea{
                width: 300px;
                height: 200px;
                font-size: 14px;

                @include mobile{
                    width: 200px;
                    height: 100px;
                }
            }

            button{
                width: 150px;
                height: 30px;
                color: white;
                background-color: crimson;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;

                &.focus{
                    outline: none;
                }
            }

            span{
                color: green;
            }
        }
    }
}